import styled from '@emotion/styled'
import Box from './Box'

export const Container = styled.div`
  display: flex;
  flex: 1;
`

export const RightContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  flex-direction: column;
`

export const LeftContainer = styled.div`
  flex: 1.5;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  padding: 50px;
`

export const Placeholder = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: default;
  color: #828282;
`

export const PlaceholderError = styled(Placeholder)`
  width: 370px;
  color: red;
  font-weight: bold;
  border: 3px solid red;
  border-radius: 20px;
  padding: 60px;
`

export const Spacer = styled.div`
  width: 20px;
  height: 20px;
  min-height: 20px;
  min-width: 20px;
`

export const CustomSpacer = styled(Spacer)`
    width: 10px;
    height: 10px;
    min-height: 10px;
    min-width: 10px;
`

export const RowLine = styled.div<{ widthPerc?: number }>`
  width: calc(${(props) => props.widthPerc?.toString() || '100'}% - 2px);
  border-bottom: 1px solid #dfe1e5;
  box-shadow: none;
`

export const EmptyListMessage = styled(Box)`
  flex: 1;
  font-weight: 400;
  font-size: 26px;
  text-align: center;
  margin-bottom: 50px;
  opacity: 0.5;
`
