import React, { Component } from 'react'
import { Page, Input, Button, Icons, Modal, SerieInfoBox, List, Box } from 'components'
import { SerieCqType } from 'api/types'
import { T, __ } from 'translations/i18n'
import styled from '@emotion/styled'
import Serie from 'api/Serie'
import { showToast } from 'shared/utils'

interface State {
  series: SerieCqType[]
  showDetailModal: boolean
  serieDetail?: SerieCqType
  loading: boolean
}

export default class QualityControlInfoSerie extends Component<{}, State> {
  state: State = {
    series: [],
    loading: false,
    showDetailModal: false,
  }

  input = React.createRef<any>()
  clearInputRef: any = undefined

  searchSerie = async (serieBarcode: string) => {
    const { series } = this.state
    try {
      this.setState({ loading: true })
      const serie = await Serie.getSerieInfo(serieBarcode)
      if (!serie) throw Error(`${serieBarcode} ${__(T.error.serie_not_found)}`)
      if (serie.error) throw Error(__(T.error.serie_not_found))
      if (series.find((s) => s.code === serie.code)) throw Error(__(T.error.serie_already_added))
      this.clearInputRef && this.clearInputRef()
      this.setState({ series: [serie, ...series], loading: false })
    } catch (error) {
      this.setState({ loading: false })
      showToast({
        sound: false,
        title: __(T.error.error),
        description: error.message,
        status: 'error',
      })
    }
  }

  clear = () => this.setState({ series: [] })

  onCloseDetailModal = () => this.setState({ showDetailModal: false, serieDetail: undefined })

  renderItem = (serie: SerieCqType) => {
    const isVas = serie.customerOrder === '24S1'
    return (
      <SerieRow bgGrey row height={100}>
        <Box flex center>
          <Label>{serie.code}</Label>
          {isVas && <Warning>{__(T.misc.is_vas)}</Warning>}
        </Box>
        <Box flex center>
          <Label>{__(T.messages[serie.status.toLowerCase()])}</Label>
        </Box>
        {serie.shippable && (
          <Box flex center>
            <Icons.CheckGreen style={{ alignSelf: 'center' }} width={48} height={48} />
          </Box>
        )}
        {!serie.shippable && (
          <Box flex center>
            <Icons.CheckRed style={{ alignSelf: 'center' }} width={48} height={48} />
          </Box>
        )}
        <Box flex center>
          <Icons.Info
            style={{ alignSelf: 'center' }}
            width={48}
            height={48}
            onClick={() => this.setState({ serieDetail: serie, showDetailModal: true })}
          />
        </Box>
      </SerieRow>
    )
  }

  render() {
    const { series, loading, showDetailModal, serieDetail } = this.state
    return (
      <Page title={__(T.titles.serie_info)}>
        <Page.Sidebar>
          <Input
            registerClear={(cb) => (this.clearInputRef = cb)}
            inputRef={this.input}
            disabled={loading}
            autoFocus={this.input.current?.value === '' || !this.input.current?.value}
            barcode
            placeholder={__(T.misc.serie_code)}
            onEnter={() => this.searchSerie(this.input.current?.value)}
          />
          {series.length > 0 && <ClearButton onClick={this.clear} variant="secondary" title={__(T.misc.clear)} />}
        </Page.Sidebar>

        <Page.Content>
          <SerieRow row style={{ justifyContent: 'space-around' }}>
            <Box flex center>
              <Label>{__(T.labels.serie)}</Label>
            </Box>
            <Box flex center>
              <Label>{__(T.misc.status)}</Label>
            </Box>
            <Box flex center>
              <Label>{__(T.labels.shippable)}</Label>
            </Box>
            <Box flex center>
              <Label>{__(T.misc.detail)}</Label>
            </Box>
          </SerieRow>
          <List data={series} loading={loading} renderItem={this.renderItem} />
        </Page.Content>
        <Modal visible={showDetailModal} onClose={this.onCloseDetailModal} size="3xl" fullContent>
          <SerieInfoBox serie={serieDetail!} />
        </Modal>
      </Page>
    )
  }
}

const ClearButton = styled(Button)`
  margin-top: 30px;
`

const Label = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: #333333;
`

const Warning = styled.span`
  font-size: 11px;
  font-weight: 500;
  color: red;
`

const SerieRow = styled(Box)`
  justify-content: space-around;
  align-items: center;
  border-radius: 10px;
  border-width: 1px;
  margin-bottom: 10px;
`
