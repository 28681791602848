import { ConfirmModalParams, ModalType, TmrPlace, TmrUser, TmrWorkstation, TmrZone } from 'api/types'
import Auth from 'api/Auth'
import Storage from 'shared/Storage'
import Workstations from 'api/Workstations'
import { showToast, __isDev, sleep } from 'shared/utils'
import { reloadApp } from 'shared/router'
import Zones from 'api/Zones'
import api from 'api/api'
import { changeLanguage as changeLanguage_i18n, T, __ } from 'translations/i18n'
import RemoteConfig from 'shared/RemoteConfig'
import Places from 'api/Places'
import DataDogLogger from 'shared/DataDogLogger'

export interface Emulation {
  name: string
  value: string
}

class AppStore {
  authToken?: string
  isInspector?: boolean

  defaultWorkstation?: TmrWorkstation
  defaultPlace?: TmrPlace
  defaultPostazione?: string
  defaultZone?: TmrZone

  workstations?: TmrWorkstation[]
  places?: TmrPlace[]

  loggedUser?: TmrUser

  zones?: TmrZone[]

  language = 'it'

  languages = ['it', 'en']

  emulation = __isDev

  showPlacesAttributes?: boolean

  dailyItems = 0

  emulationList: Emulation[] = []

  confirmModalParams: ConfirmModalParams = { title: '', message: '', onConfirm: () => {}, onCancel: () => {} }
  toggleConfirmModalHandler?: (show: boolean) => void

  openModal?: (modal: ModalType) => void
  closeModal?: (id: string) => void

  reloadRouting!: () => void

  async loadInitalData() {
    //check language
    this.language = await Storage.load('language', 'it')
    changeLanguage_i18n(this.language)

    //check if is present a custom endpoint
    api.setBaseURL(await Storage.load('endpoint', api.getBaseURL()))

    await this.loadAuthToken()
    if (this.authToken) {
      try {
        this.loggedUser = await Auth.loggedUser()
        if (this.loggedUser) {
          this.workstations = await Workstations.searchByPlaceId(this.loggedUser.place.id)
          this.places = await Places.search()

          if (this.workstations && this.workstations.length === 1) {
            await this.saveDefaultWorkstation(this.workstations[0])
          } else if (this.workstations && this.workstations.length > 1) {
            const dWorkstation = await this.getDefaultWorkstation()
            this.defaultWorkstation = this.workstations?.find((w) => w.id === dWorkstation?.id)
          }

          this.isInspector = !!this.loggedUser?.roles.find((role) => role.code === 'cq-inspector')

          const dPlace = await this.getDefaultPlace()
          this.defaultPlace = this.places?.find((p) => p.id === dPlace?.id)

          if (!this.isInspector) {
            this.saveDefaultPlace(this.loggedUser.place)
            this.defaultPlace = this.loggedUser.place
          }

          const idPostazione = await this.getDefaultPostazione()
          this.defaultPostazione = idPostazione

          this.zones = await Zones.search({ 'place.id': this.loggedUser.place.id })

          this.dailyItems = await Storage.load(`dailyItems`, 0)

          this.emulation = await Storage.load(`emulation`, false)

          this.emulationList = await this.loadEmulationList()

          DataDogLogger.setUser(this.loggedUser, this.isInspector) //Set the user for DataDog events

          // load remote operations configurations
          await RemoteConfig.load()
        }
      } catch (err) {
        showToast({
          title: __(T.error.error),
          description: err?.message ?? 'Check capabilities',
          status: 'error',
        })
        this.logout()
        throw err
      }
    }
  }

  async getEndpoint() {
    return Storage.load('endpoint', api.getBaseURL())
  }

  async changeLanguage(language: string) {
    this.language = language
    await Storage.save('language', language)
    changeLanguage_i18n(language)
  }

  async setEndpoint(endpoint) {
    await Storage.save('endpoint', endpoint)
    reloadApp()
  }

  getZoneByType(type: 'STOCK' | 'SOLD' | 'INBOUND' | 'LOST') {
    if (!this.zones) return undefined

    return this.zones.find((zone) => zone.zoneType === type)
  }

  async enableEmulation(emulation = true) {
    this.emulation = emulation
    await Storage.save(`emulation`, emulation)
  }

  async increaseDailyItems() {
    this.dailyItems++
    await Storage.save(`dailyItems`, this.dailyItems)
  }

  async resetDailyItems() {
    this.dailyItems = 0
    await Storage.save(`dailyItems`, 0)
  }

  async saveDefaultWorkstation(workstation: TmrWorkstation) {
    this.defaultWorkstation = workstation
    await Storage.save(`defaultWorkstation`, workstation)
    return this.defaultWorkstation
  }

  async saveDefaultPlace(place: TmrPlace) {
    this.defaultPlace = place
    await Storage.save(`defaultPlace`, place)
    return this.defaultPlace
  }

  async saveDefaultZone(zone: TmrZone) {
    this.defaultZone = zone
    await Storage.save(`defaultZone`, zone)
    return this.defaultZone
  }

  async getDefaultWorkstation() {
    this.defaultWorkstation = await Storage.load(`defaultWorkstation`, this.defaultWorkstation)
    return this.defaultWorkstation
  }

  async getDefaultPlace() {
    this.defaultPlace = await Storage.load(`defaultPlace`, this.defaultPlace)
    return this.defaultPlace
  }

  async setDefaultPostazione(postazione: string) {
    const parsed = postazione.replace(' ', '').trim()
    this.defaultPostazione = parsed
    await Storage.save('defaultPostazione', parsed)
    return this.defaultPostazione
  }

  async getDefaultPostazione() {
    this.defaultPostazione = await Storage.load(`defaultPostazione`, this.defaultPostazione)
    return this.defaultPostazione
  }

  async saveAuthToken(token?: string) {
    this.authToken = token
    await Storage.save(`authToken`, token)
    return this.authToken
  }

  async logout() {
    // await Auth.logout()
    DataDogLogger.removeUser()
    Storage.remove(`authToken`)
    this.authToken = undefined
    Storage.remove(`defaultWorkstation`)
    this.defaultWorkstation = undefined
    Storage.remove(`dailyItems`)
    Storage.remove(`emulation`)
    this.loggedUser = undefined
    const isSsoAuth = await Storage.load('ssoAuth')
    if (isSsoAuth) {
      Storage.remove(`ssoAuth`)
      await sleep(3000)
      window.location.assign(
        `https://b2csfdev.b2clogin.com/b2csfdev.onmicrosoft.com/B2C_1_TMR_Login_Signin/oauth2/v2.0/logout?post_logout_redirect_uri=${window.location.origin}`
      )
    } else if (this.reloadRouting) this.reloadRouting()
    else reloadApp()
  }

  async loadAuthToken() {
    this.authToken = await Storage.load(`authToken`, this.authToken)
    Auth.setHeaderAccessToken(this.authToken!)
    return this.authToken
  }

  async saveEmulationList(emulationList: Emulation[]) {
    this.emulationList = emulationList
    await Storage.save(`emulationList`, emulationList)
    return this.emulationList
  }

  async loadEmulationList() {
    this.emulationList = await Storage.load(`emulationList`, this.emulationList)
    return this.emulationList
  }

  setConfirmModalHandler(handler) {
    this.toggleConfirmModalHandler = handler
  }

  showConfirmModal(title: string, message: string, onConfirm: () => void, onCancel?: () => void) {
    this.confirmModalParams = { title, message, onConfirm, onCancel: onCancel ?? undefined }
    this.toggleConfirmModalHandler?.(true)
  }

  hideConfirmModal() {
    this.toggleConfirmModalHandler?.(false)
  }
}

export default new AppStore()
