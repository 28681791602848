import styled from '@emotion/styled'
import { SerieCqType } from 'api/types'
import { Box, Card, InfoCard, Spacer, Icons } from 'components'
import React, { Component } from 'react'
import { T, __ } from 'translations/i18n'

interface Props {
  serie: SerieCqType
}

export default class SerieInfoBox extends Component<Props> {
  render() {
    const { serie } = this.props
    const { code, status, shippable, sizeFit, model, variant, season, workerCode, quantity, workedTags, testedTags } =
      serie
    return (
      <Box style={{ backgroundColor: '#F2F2F2', padding: 20 }}>
        <InfoCard
          item={{ code, status, sizeFit, model, variant, season, workerCode }}
          title={__(T.labels.serie)}
          subtitle={serie.code}
        />
        <Spacer />
        <CounterContainer>
          <Counter>
            {__(T.labels.shippable)}
            {shippable ? (
              <Icons.CheckGreen style={{ alignSelf: 'center' }} width={48} height={48} />
            ) : (
              <Icons.CheckRed style={{ alignSelf: 'center' }} width={48} height={48} />
            )}
          </Counter>
        </CounterContainer>
        <Spacer />
        <CounterContainer row>
          <Card title={__(T.placeholder.total_pieces)} center nomargin style={{ minWidth: 125, padding: 5 }}>
            <Counter>{quantity}</Counter>
          </Card>
          <Card title={__(T.placeholder.associated)} center nomargin style={{ minWidth: 125, padding: 5 }}>
            <Counter>{workedTags}</Counter>
          </Card>
          <Card title={__(T.placeholder.tested)} center nomargin style={{ minWidth: 125, padding: 5 }}>
            <Counter>{testedTags}</Counter>
          </Card>
        </CounterContainer>
      </Box>
    )
  }
}

const CounterContainer = styled(Box)`
  justify-content: space-between;
`

const Counter = styled(Box)`
  min-width: 80px;
  font-size: 20px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
`
