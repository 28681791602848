import React, { useState } from 'react'
import styled from '@emotion/styled'
import Box from './Box'
import Icons from './Icons'
import { Spacer } from './Various'
import { Card } from './index'
import { __, T } from '../translations/i18n'

export default function QualityControlQuantity({ quantityToAdvance, checkMaxQuantity, onIncrementQuantity, onDecrementQuantity, enableIcons }: { quantityToAdvance: number; checkMaxQuantity: () => boolean; onDecrementQuantity: () => void; onIncrementQuantity: () => void; enableIcons: boolean }) {

  return (
    <Box row vcenter>
      <Card title={__(T.misc.quantity_to_advance)} nomargin style={{ flexGrow: 1, padding: 5, paddingLeft: 10 }}>
        <Counter>{quantityToAdvance}</Counter>
      </Card>
      {enableIcons && (
        <>
          <Spacer/>
          <Icons.Plus onClick={() => {
            if (checkMaxQuantity()) return null
            onIncrementQuantity()
          }} />
          <Spacer/>
          <Icons.Minus onClick={() => {
            if (quantityToAdvance <= 0) return null
            onDecrementQuantity()
          }} />
        </>
      )}
    </Box>
  )
}

const Counter = styled(Box)`
    min-width: 80px;
    font-size: 20px;
    font-weight: bold;
`