export default {
  titles: {
    encoding: 'Encoding',
    inbound: 'Inbound',
    user_info: 'Info Utente',
    settings: 'Impostazioni',
    reset_daily_items: 'Reset items giornalieri?',
    last_parcels: 'Ultimi colli',
    select_item: 'Seleziona item',
    select_product: 'Seleziona prodotto',
    select_workstation: 'Seleziona postazione',
    select_language: 'Seleziona lingua',
    transfer_to_zone: 'Trasferimento a Zona',
    support: 'Supporto',
    select_printer: 'Seleziona stampante',
    shipment_to_confirm: 'Spedizioni da confermare',
    quality_control: 'Quality control',
    serie_lot_info: 'Info Serie/Lotto',
    tag_info: 'Tag Info',
    serie_info: 'Info Serie',
  },
  fields: {
    current_location: 'Posizione Attuale',
    identified_item: 'Item Identificato',
    ignored_items: 'Item ignorati',
    error_items: 'Item in errore',
    warning_items: 'Item in warning',
  },
  misc: {
    is_vas: 'ATTENZIONE VAS',
    admin: 'Admin',
    save: 'Salva',
    confirm: 'Conferma',
    cancel: 'Annulla',
    close: 'Chiudi',
    user: 'Utente',
    workstation: 'Postazione',
    username: 'Username',
    name: 'Nome',
    password: 'Password',
    login_tmr: 'Login TMR',
    login: 'Login',
    sso_login: 'SSO Login',
    encoding: 'Encoding',
    ean_code: 'Codice EAN',
    certilogo: 'Certilogo',
    production_order: 'Numero di serie',
    enter_the_necessary_fields: 'Inserisci i campi necessari per iniziare l’operazione',
    associated_items: 'Item associati',
    associate: 'Associa',
    verify: 'Verifica',
    reading: 'In lettura',
    association: 'Associazione',
    waiting: 'In attesa',
    writing: 'Scrittura',
    retry: 'Riprova',
    writing_association: 'Scrittura e Associazione',
    confirmed: 'Confermato',
    unable_to_write: `Impossibile scrivere il tag: {{code}} per il prodotto: {{productCode}}`,
    unable_to_get_next_epc: `Impossibile ottenere il prossimo epc del prodotto: {{productCode}}`,
    unable_to_connect_to_workstation: 'Impossibile connettersi alla postazione',
    tag_info: 'Tag Info',
    tag_info_cq: 'Tag Info CQ',
    serie_info_cq: 'Info Serie',
    quality_control: 'Controllo Qualità',
    stop: 'Ferma',
    start: 'Avvia',
    pause: 'Pausa',
    clear: 'Clear',
    ready_to_receive: 'Pronto per ricezione',
    change_password: 'Cambia password',
    logout: 'Effettua logout',
    are_you_sure_to_logout: 'Vuoi procedere con il logout?',
    settings: 'Impostazioni',
    roles: 'Ruoli',
    email: 'Email',
    place: 'Luogo',
    cqType: 'CQ Type',
    items: 'Item',
    description: 'Descrizione',
    color: 'Colore',
    size: 'Calzata',
    total_reads: 'LETTURE TOTALI',
    save_to_list: 'Salva nella lista',
    destination: 'Destinazione',
    origin: 'Origine',
    new_parcel: 'Nuovo Collo',
    status: 'Stato',
    parcel: 'Collo',
    parcels: 'Colli',
    shipment: 'Spedizione',
    creation_date: 'Data Creazione',
    search: 'Cerca',
    detail: 'Dettaglio',
    quantity: 'Quantità ({{quantity}})',
    style: 'Stile',
    material: 'Materiale',
    shipment_code_auto: 'Codice Spedizione (auto)',
    parcel_code_auto: 'Codice Collo (auto)',
    next: 'Avanti',
    parcels_to_receive: 'Colli da ricevere',
    confirm_parcel: 'Conferma Collo',
    image_missing: 'IMMAGINE MANCANTE',
    loading: 'Caricamento...',
    unknown_product: 'Prodotto sconosciuto',
    unknown_tag: 'Tag sconosciuto',
    invalid_tag: 'Tag non battezzato',
    stock: 'Stock',
    valid: 'Valido',
    unknown: 'Unknown',
    actions: 'Azioni',
    reprint: 'Ristampa',
    product: 'Prodotto',
    report_repair: 'Segnala Riparazione',
    reason: 'Motivazione',
    request_code_change: 'Richiedi Cambio Codice',
    enable_workstation_emulation: 'Abilita emulazione Workstation',
    enable_place_attributes: 'Abilita attributi Place',
    filter: 'Filtro',
    language: 'Lingua',
    warning: 'Warning',
    success: 'Success',
    info: 'Info',
    checklist: 'Checklist',
    optional: 'Opzionale',
    phone: 'Telefono',
    printer: 'Stampante',
    new_shipment: 'Nuova Spedizione',
    upc: 'UPC',
    model_part_color_size: 'Modello / Parte / Colore / Calzata',
    confirm_shipment: 'Conferma Spedizione',
    receive: 'Ricevi',
    delete_association: 'Elimina Associazione',
    open: 'Apri',
    variant: 'Variante',
    variant_description: 'Descrizione Variante',
    sku: 'Sku',
    fit: 'Taglia',
    product_header: 'Modello / Variante Estetica / Descr. Modello / Descrizione V.E. / Sku / Calzata / Taglia',
    serie: 'Serie',
    total_quantity_serie: 'Quantità Totale Serie Originale',
    total_quantity_lots: 'Quantità Totale Lotti',
    shipable_quantity: 'Quantità Spedibile',
    quantity_to_advance: 'Quantità da avanzare',
    encoded_items_on_serie: 'Esistono pezzi battezzati sulla serie. Usa l\'operazione di cancellazione per rimuoverli',
    select_lot_s: 'Seleziona lotto/i',
    start_date: 'Data inizio: {{startDate}}',
    planned_lot: 'Lotto pianificato',
    not_planned_lot: 'Lotto non pianificato',
    proceed: 'Proseguire?',
    confirmed_lots: 'LOTTI CONFERMATI',
    serie_note: 'Note serie',
    quality_sheet: 'Scheda qualità',
    lot_info: 'Info lotto/i',
    checking_dont_move: 'Verifica in corso... Non muovere l\'item dal lettore!',
    writing_dont_move: 'Scrittura in corso... Non muovere l\'item dal lettore!',
    lot_total_pieces: 'Totale pezzi lotto',
    to_be_tested: 'Da ispezionare',
    confirm_lots: 'Conferma lotto/i',
    interrupt_lots: 'Interrompi lotto/i',
    add_discard_causal: 'Aggiungi causale di scarto',
    scan_size_fit_code_to_discard: 'Scansiona un codice taglia/calzata da scartare',
    items_limit_reached_wsm2: 'Limite pezzi raggiunto per taglia e calzata selezionate oppure controllare quantità avanzate su WSM2',
    lot_closed_cannot_encode_items: 'Lotto chiuso correttamente, impossibile battezzare altri tag',
    cannot_encode_items_check_wsm2: 'Impossibile battezzare altri tag, controllare avanzamento in WSM2',
    warning_message_in_serie: 'Messaggio di warning nella serie',
    warning_item_just_encoded: 'Stai per scartare un pezzo appena battezzato. Continuare?',
    item_discarded_with_causal_proceed: 'Pezzo scartato con la seguente causale: "{{causal}}". Continuare?',
    no_open_lot: 'Nessun lotto aperto',
    encodable_items: 'Totale pezzi battezzabili',
    close_lot: 'Chiudi lotto',
    inspected_1: 'In carico al lavorante',
    ready_inspection_2: 'Pronta per il collaudo',
    inspected_2: 'In carico all\'ispettore',
    suspended: 'Sospeso',
    closed: 'Chiuso',
    interrupted: 'Interrotto',
    reset: 'Reset',
    serie_code: 'Codice serie',
    lot_has_discarded_items: "Il lotto/i contiene pezzi scartati. Chiudere manualmente il lotto/i",
  },
  error: {
    serie_already_added: 'Serie già aggiunta',
    serie_not_found: 'Serie non trovata',
    error: 'Errore',
    forbidden: 'Forbidden',
    not_found: 'Not Found',
    unauthorized: 'Unauthorized',
    too_many_tags_read: 'Hai letto troppi tag (attesi {{exptected}} letti {{read}})',
    too_many_checklist_types: 'Più di un tipo di checklist trovato. Assicurati di selezionare colli con checklist dello stesso tipo',
    certilogo_not_found: 'Certilogo "{{code}}" non trovato',
    ean_not_found: 'EAN "{{code}}" non trovato',
    production_order_not_found: 'Numero di serie "{{code}}" non trovato',
    no_inbound_zones: 'Nessuna zona di Inbound',
    no_operation_config: 'Configurazione operazione non trovata',
    not_supported_reading_mode: 'Modalità di lettura non supportata',
    undefined_operation_config: 'La configurazione non è definita',
    certilogo_already_encoded: 'Certilogo "{{code}}" già encodato',
    tag_not_found: 'Tag non trovato',
    tag_unassociated: 'Tag non associato',
    tag_type_unexpected: 'Non è previsto un tag di questo tipo',
    tag_already_associated: 'Il tag è già associato ad un altro item',
    tags_not_associated: 'Uno o più tag non è associato ad alcun item',
    tags_mismatch: 'Il tag è associato ad un altro item',
    workstation_could_not_be_started: 'La workstation non può essere avviata',
    authentication_failed: 'Autenticazione fallita',
    destination_field_required: 'Campo destinazione obbligatorio',
    unable_to_connect_to_rfid_reader: 'Impossibile avviare il reader. Verifica che il reader sia acceso',
    items_in_error_found: 'Trovati prodotti in errore',
    item_not_in_inbound: 'Prodotto non in inbound',
    item_not_in_stock: 'Prodotto non a stock',
    item_in_another_parcel: 'Prodotto in un altro collo',
    item_in_another_production_order: 'Prodotto in un altro ordine di produzione',
    field_is_mandatory: '{{field}} è obbligatorio',
    field_must_be_number: '{{field}} deve essere numerico',
    serie_status_change: 'Errore nel cambio stato della serie in ',
    no_discard_causal_selected: 'Causale di scarto non selezionata',
    no_product_selected: 'Prodotto non selezionato',
    not_associated_product: 'Prodotto non appartenente alla serie o taglia/calzata errata',
    not_collaudated_product: 'Prodotto non battezzato',
    status_not_admitted: 'Stato non ammesso',
    serie_code_10_characters: 'Codice serie diverso da 10 caratteri',
    serie_code_14_characters: 'Codice prodotto diverso da 14 caratteri',
    serie_wrong_place: "Serie non appartenente al place dell'utente",
    serie_inspector_check: "La serie deve essere controllata dall'ispettore",
    product_already_associated: 'Questo prodotto risulta già associato alla serie',
    product_already_dropped: 'Questo prodotto risulta scartato',
    tag_reset_error: "Errore nell'operazione di reset del tag",
    product_drop_error: 'Errore nello scarto del prodotto',
    reader_connection_error: 'Impossibile connettersi al lettore',
    product_irreversible_dropped: 'Questo prodotto è in stato scartato in modalità irreversibile',
    product_already_associated_by_inspector: "Questo prodotto risulta già collaudato dall'ispettore",
    serie_error: 'Errore con la serie',
    product_not_selected: 'Prodotto non selezionato',
    serie_limit_associate_error: 'Limite pezzi collaudati raggiunto',
    writing_tag_error: "Errore nell'operazione di scrittura del tag",
    product_association_error: "Errore nell'associazione del prodotto",
    larger_file_error: 'La dimensione massima di caricamento è 5MB',
    user_disabled: 'L’utente non risulta attivo, contattare i sistemi informativi',
    cq_type_not_found: 'CQ Type non trovato',
    counters_error: 'Impossibile definire contatori serie',
    fit_size_not_found: 'Taglia/calzata non trovata',
    no_lot_to_be_inspected: 'Nessun lotto da ispezionare',
    no_lot_selected: 'Nessun lotto selezionato',
    row_not_found: 'Riga non trovata',
    select_causal_before_proceeding: 'Imposta una causale prima di procedere',
    reading_error: 'Errore di lettura',
    cannot_delete_no_item_encoded: 'Impossibile eseguire cancellazione, nessun item associato',
    no_items_tested_cant_advance_lot: 'Nessun pezzo collaudato, impossibile avanzare il lotto',
    no_items_encoded_cant_advance_lot: 'Nessun pezzo battezzato, impossibile avanzare il lotto',
    testing_sheet_not_set: 'Foglio di collaudo non impostato',
    encode_one_item_before_close_lot: 'Associare almeno un item prima di chiudere il lotto',
    read_product_not_valid: 'Prodotto letto non valido',
    tested_items_quantity_doesnt_reach_min_quantity: 'La quantità di pezzi collaudati non raggiunge la quantità minima di test',
    FERRAGAMO_ERROR_BARCODE_NOT_FOUND: 'Serie non presente su FAST',
    FERRAGAMO_ERROR_FAST_CONNECTION: 'Errore di connessione a FAST',
    FERRAGAMO_ERROR_SERIES_COMPETENCE_ERROR: 'Non è una serie di produzione',
    FERRAGAMO_ERROR_SERIES_STATUS_ERROR: 'La serie non è in uno stato corretto',
    FERRAGAMO_ERROR_SERIES_STATUS_ERROR_READY_INSPECTION_2: 'La serie è in stato "da ispezionare"',
    FERRAGAMO_ERROR_SERIES_STATUS_ERROR_INSPECTED_2: 'La serie è in stato "in ispezione"',
    FERRAGAMO_ERROR_SERIES_STATUS_ERROR_INSPECTED_1: 'La serie è in carico al lavorante',
    FERRAGAMO_ERROR_SERIES_STATUS_ERROR_READY_INSPECTION_1: 'La serie è in stato "da lavorare"',
    FERRAGAMO_ERROR_SERIES_STATUS_ERROR_READY_SUSPENDED: 'La serie è in stato "sospeso"',
    FERRAGAMO_ERROR_SERIES_TAG_NEEDED_ERROR: 'Serie con qualityCheckTag diverso da Y su Fast',
    FERRAGAMO_ERROR_TAG_NOTFOUND_ERROR: 'Tag non trovato su Fast',
    FERRAGAMO_ERROR_TAG_STATUS_ERROR_ALREADY_ENCODED: 'Pezzo già battezzato',
    FERRAGAMO_ERROR_TAG_STATUS_ERROR_ALREADY_TESTED: 'Pezzo già collaudato',
    FERRAGAMO_ERROR_TAG_STATUS_ERROR_TESTED_BY_INSPECTOR: 'Pezzo già collaudato dall’ispettore',
    FERRAGAMO_ERROR_SERIES_MISMATCH_ERROR: 'Pezzo non della taglia/calzata letta o pezzo non della serie letta',
    FERRAGAMO_CQ_ENCODING_ERROR_ORDER_ROW_NOT_FOUND: "Riga d'ordine non trovata",
    FERRAGAMO_CQ_ENCODING_ERROR_ORDER_ROW_PRODUCT_MISMATCH: 'Il pezzo che si sta tentando di battezzare è stato già assegnato a modello/taglia-calzata differente. Se necessario cancellare',
    FERRAGAMO_CQ_ENCODING_ERROR_ORDER_ROW_ALL_ITEMS_ALREADY_ENCODED: 'Tutti i prodotti sono già stati collaudati per il modello selezionato',
    ENCODING_ERROR_PRODUCT_NOT_IN_PRODUCTION_ORDER: "Il prodotto non appartiene all'ordine inserito",
    FERRAGAMO_ERROR_TAG_STATUS_ERROR_ENCODED: 'Pezzo battezzato: il pezzo passa da stato buono a stato scarto',
    WEBRESOURCE_GENERIC_ERROR: 'Errore generico',
    product_already_read: 'Product already read',
    FERRAGAMO_ERROR_SERIES_STATUS_ERROR_CLOSED: 'Serie già collaudata',
    FERRAGAMO_CQ_RESET_ERROR_ITEM_NOT_IN_OPEN_LOT: 'Pezzo non appartenente ad un lotto aperto',
    FERRAGAMO_CQ_RESET_ERROR_ITEM_ITEM_IN_CLOSED_LOT: 'Pezzo appartenente ad un lotto chiuso',
    FERRAGAMO_CQ_TESTING_NO_SELECTED_LOTS_BOUND_TO_ITEM: 'Pezzo appartenente ad un lotto che non è stato preso in carico',
    FERRAGAMO_ERROR_TAG_STATUS_ERROR_NOT_REVERSIBLE_DISCARDED: 'Pezzo scartato in maniera non reversibile',
  },
  messages: {
    product_associated_correctly: 'Prodotto battezzato correttamente',
    product_dropped_correctly: 'Prodotto scartato correttamente',
    product_cancel_correctly: 'Associazione cancellata',
    product_already_dropped_info: 'Questo prodotto risulta già scartato con causale',
    product_association_proceed: 'Procedere con il collaudo?',
    serie_confirm_interrupt: 'Conferma interruzione',
    serie_interrupt_association_question: 'Sei sicuro di voler interrompere il collaudo?',
    serie_closed: 'Serie chiusa correttamente',
    serie_interrupted_correctly: 'Serie interrotta correttamente',
    inspected_1: 'Lavorante',
    ready_inspection_2: 'Da ispezionare',
    inspected_2: 'Ispettore',
    suspended: 'Sospesa',
    closed: 'Chiusa',
    correct: 'Correttamente',
    drop_proceed: 'Procedere con lo scarto?',
    serie_change_confirm: 'Conferma cambio serie',
    serie_close_product_not_associated: "Il prodotto appartiene ad un'altra serie, sicuro di voler chiudere la serie corrente?",
    near_product_to_reader: 'Avvicina il prodotto al lettore',
    no_workstations_found: 'Nessuna Postazione trovata',
    no_workstation_selected: 'Nessuna Postazione selezionata',
    no_place_selected: 'Nessun Place selezionato',
    no_seat_selected: 'Inserisci un ID postazione',
    are_you_sure_to_reset_daily_items: 'Sei sicuro di voler resettare gli item giornalieri?',
    bring_an_item_to_antenna: "Avvicina un prodotto all'antenna",
    press_start_to_read: 'Premi Start per iniziare \na leggere i prodotti',
    paste_comma_separeted_tags: 'Incolla i tags separati da virgola e premi Enter...',
    connection_workstation_progress: 'Connessione alle antenne in corso...',
    operation_success: 'Operazione completata con successo',
    outbound_success: 'Outbound completata con successo',
    inbound_success: 'Inbound completata con successo',
    transfer_to_zone_success: 'Trasferimento a Zona completato con successo',
    list_empty: 'Lista vuota',
    looks_like_anything_in_list: 'Sembra che la tua lista sia vuota',
    confirm_change_option: 'Conferma cambio opzione',
    serie_clear_causals: 'Sei sicuro di cambiare opzione? Le causali di scarto verranno cancellate',
    no_cq_type: 'Il lavorante non ha un CQ Type associato',
    lot_doesnt_contain_testable_items: 'Il lotto {{lotCode}} non contiene pezzi collaudabili e verrà interrotto',
    lots_dont_contain_testable_items: 'I lotti {{lotCodes}} non contengono pezzi collaudabili e verranno interrotti',
    no_items_tested_lot_interrupted: 'Nessun pezzo collaudato, lotto interrotto',
    lot_closed: 'Lotto/i chiuso/i correttamente',
    lot_interrupted: 'Lotto/i interrotto/i correttamente',
    select_discard_type: 'Seleziona un tipo di scarto',
    vas_serie: 'ATTENZIONE: SERIE VAS',
  },
  placeholder: {
    total_pieces: 'Totale pezzi',
    associated: 'Battezzati',
    to_be_tested: 'Da Collaudare',
    drop_causal: 'Causale scarto',
    tested: 'Collaudati',
    dropped: 'Scartati',
    remain: 'Rimanenti',
    confirm_serie: 'Conferma serie',
    suspend_serie: 'Sospendi serie',
    interrupt_association: 'Interrompi Collaudo',
    start_reader: 'Avvia lettore',
    stop_reader: 'Ferma lettore',
    continue: 'Continua',
    attention: 'Attenzione',
    search_shipment_parcel: 'Cerca Commessa o Collo',
    search_product_parcel: 'Cerca Prodotto o Collo',
    reversible: 'Reversibile',
    irreversible: 'Irreversibile',
    serie_barcode: 'Barcode serie',
  },
  confirm: {
    discrepancy_title: 'Collo con discrepanze',
    discrepancy_message: 'Trovate discrepanze nel collo. Sei sicuro di voler confermare?',
    confirm_delete_association: 'Sei sicuro di voler disassociare questo prodotto?',
    serie_found_discrepancy: 'Discrepanze trovate. Sei sicuro di voler confermare la serie?',
    discarded_pieces_title: 'Conferma con pezzi scartati',
    discarded_pieces_close_message: "Risultano dei pezzi scartati all'interno della serie, vuoi continuare con la chiusura?",
    discrepancy_total_encoded_pieces: 'Attenzione: serie con discrepanze tra i pezzi totali e quelli encodati; prestare massima attenzione e controllo anche ai pezzi non encodati',
  },
  labels: {
    shippable: 'Spedibile',
    serie: 'Serie',
    lot: 'Lotto',
    draft: 'Bozza',
    ready_to_send: 'Pronto per spedizione',
    send_await: 'In attesa',
    outbound_error: 'Errore Outbound',
    sent: 'Inviato',
    in_inbound: 'In Inbound',
    receive_await: 'In attesa',
    ready_to_receive: 'Pronto per ricezione',
    inbound_error: 'Errore Inbound',
    received: 'Ricevuto',
    in_transit: 'In transito',
    testing: 'Collaudo',
    discard: 'Scarto',
    delete: 'Cancellazione',
  },
  caps_lock: {
    lot: 'LOTTO',
    status: 'STATO',
    quantity: 'QUANTITÀ',
    shippable: 'SPEDIBILE',
    details: 'DETTAGLIO'
  }
}
