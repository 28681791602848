import React, { useState } from 'react'
import styled from '@emotion/styled'
import Box from './Box'
import Icons from './Icons'
import { Spacer } from './Various'
import { Card, Select } from './index'
import { __, T } from '../translations/i18n'
import { Causal } from '../api/types'

export default function QualityControlCausal({ id, onDecrement, onIncrement, onQuantityChange, checkMaxQuantity, checkSelectedCausals }: { id: string; onDecrement: (id: string) => void; onIncrement: (id: string) => void; onQuantityChange: (causalId: string, quantity: number, oldValue: string | null) => void; checkMaxQuantity: () => boolean; checkSelectedCausals: () => Causal[] }) {
  const [quantity, setQuantity] = useState(0)
  const [selectedCausal, setSelectedCausal] = useState<string | null>(null)

  return (
    <>
      <Spacer/>
      <Box row vcenter>
        <Select
          onSelect={(causalSel) => {
            const oldValue = selectedCausal
            setSelectedCausal(causalSel.id)
            onQuantityChange(causalSel.id, quantity, oldValue)
          }}
          placeholder={__(T.placeholder.drop_causal)}
          options={checkSelectedCausals()}
          config={{ value: 'id', label: 'fastId', secondaryLabel: 'description' }}
          multiLabel
          containerStyle={{ flexGrow: 1 }}
        />
        <Spacer/>
        <Card nomargin style={{ minWidth: 125, padding: 5, paddingLeft: 10 }}>
          <Counter>{quantity}</Counter>
        </Card>
        <Spacer/>
        <Icons.Plus onClick={() => {
          if (selectedCausal !== null) {
            if (checkMaxQuantity()) return null
            setQuantity(quantity + 1)
            onDecrement(id)
            onQuantityChange(selectedCausal, quantity + 1, selectedCausal)
          }
        }} />
        <Spacer/>
        <Icons.Minus onClick={() => {
          if (selectedCausal !== null) {
            if (quantity <= 0) return null
            setQuantity(quantity - 1)
            onIncrement(id)
            onQuantityChange(selectedCausal, quantity - 1, selectedCausal)
          }
        }} />
      </Box>
    </>
  )
}

const Counter = styled(Box)`
    min-width: 80px;
    font-size: 20px;
    font-weight: bold;
`