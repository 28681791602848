export default {
  titles: {
    encoding: 'Encoding',
    inbound: 'Inbound',
    user_info: 'User Info',
    settings: 'Settings',
    reset_daily_items: 'Reset daily items?',
    last_parcels: 'Last Parcels',
    select_item: 'Select Item',
    select_product: 'Select Product',
    select_workstation: 'Select workstation',
    select_language: 'Select language',
    transfer_to_zone: 'Transfer to zone',
    support: 'Support',
    select_printer: 'Select printer',
    shipment_to_confirm: 'Shipment to Confirm',
    quality_control: 'Quality Control',
    serie_lot_info: 'Serie/Lot Info',
    tag_info: 'Tag Info',
    serie_info: 'Serie Info',
  },
  fields: {
    current_location: 'Current Location',
    identified_item: 'Identified Item',
    ignored_items: 'Ignored Items',
    error_items: 'Error Items',
    warning_items: 'Warning Items',
  },
  misc: {
    is_vas: 'ATTENTION VAS',
    admin: 'Admin',
    save: 'Save',
    confirm: 'Confirm',
    cancel: 'Cancel',
    close: 'Close',
    user: 'User',
    workstation: 'Workstation',
    username: 'Username',
    name: 'Name',
    password: 'Password',
    login: 'Login',
    login_tmr: 'Login TMR',
    sso_login: 'SSO Login',
    encoding: 'Encoding',
    ean_code: 'EAN Code',
    certilogo: 'Certilogo',
    production_order: 'Serial number',
    enter_the_necessary_fields: 'Enter the necessary fields to start the operation',
    associated_items: 'Associated Items',
    associate: 'Associate',
    verify: 'Verify',
    reading: 'Reading',
    association: 'Association',
    waiting: 'Waiting',
    writing: 'Writing',
    retry: 'Retry',
    writing_association: 'Writing and association',
    confirmed: 'Confirmed',
    unable_to_write: `Unable to write epc tag : {{code}} for product: {{productCode}}`,
    unable_to_get_next_epc: `Unable to get next epc for: {{productCode}}`,
    unable_to_connect_to_workstation: 'Unable to connect to workstation',
    tag_info: 'Tag Info',
    tag_info_cq: 'Tag Info CQ',
    serie_info_cq: 'Info Serie',
    quality_control: 'Quality Control',
    inbound: 'Inbound',
    outbound: 'Outbound',
    stop: 'Stop',
    start: 'Start',
    pause: 'Pause',
    clear: 'Clear',
    tag_encoded_today: 'Tag Encoded today',
    ready_to_receive: 'Ready to receive',
    change_password: 'Change Password',
    logout: 'Logout',
    are_you_sure_to_logout: 'You really want to log out?',
    settings: 'Settings',
    roles: 'Roles',
    email: 'Email',
    place: 'Place',
    cqType: 'CQ Type',
    items: 'Items',
    description: 'Description',
    color: 'Color',
    size: 'Size',
    total_reads: 'TOTAL READS',
    save_to_list: 'Save to list',
    destination: 'Destination',
    origin: 'Origin',
    new_parcel: 'New Parcel',
    status: 'Status',
    parcel: 'Parcel',
    parcels: 'Parcels',
    shipment: 'Shipment',
    creation_date: 'Creation Date',
    search: 'Search',
    detail: 'Detail',
    quantity: 'Quantity ({{quantity}})',
    style: 'Style',
    material: 'Material',
    shipment_code_auto: 'Shipment code (auto)',
    parcel_code_auto: 'Parcel code (auto)',
    next: 'Next',
    parcels_to_receive: 'Parcels to receive',
    confirm_parcel: 'Confirm Parcel',
    image_missing: 'IMAGE MISSING',
    loading: 'Loading...',
    unknown_product: 'Unknown Product',
    unknown_tag: 'Unknown Tag',
    invalid_tag: 'Tag Not Associated',
    stock: 'Stock',
    valid: 'Valid',
    unknown: 'Unknown',
    actions: 'Actions',
    reprint: 'Reprint',
    product: 'Product',
    report_repair: 'Report Repair',
    reason: 'Reason',
    request_code_change: 'Request Code Change',
    enable_workstation_emulation: 'Enable Workstation emulation',
    enable_place_attributes: 'Enable Place Attributes',
    filter: 'Filter',
    language: 'Language',
    warning: 'Warning',
    success: 'Success',
    info: 'Info',
    checklist: 'Checklist',
    optional: 'Optional',
    phone: 'Phone',
    printer: 'Printer',
    new_shipment: 'New Shipment',
    upc: 'UPC',
    model_part_color_size: 'Model / Part / Color / Size',
    confirm_shipment: 'Confirm Shipment',
    receive: 'Receive',
    delete_association: 'Delete Association',
    open: 'Open',
    variant: 'Variant',
    variant_description: 'Variant description',
    sku: 'Sku',
    fit: 'Fit',
    product_header: 'Model / Aeshetic Variant / Descr. Model / Description A.V. / Sku / Size / Fit',
    serie: 'Serie',
    total_quantity_serie: 'Original Total Serie Quantity',
    total_quantity_lots: 'Total Lots Quantity',
    shipable_quantity: 'Shipable Quantity',
    quantity_to_advance: 'Quantity to advance',
    encoded_items_on_serie: 'There are pieces named in the series. Use the delete operation to remove them',
    select_lot_s: 'Select Lot(s)',
    start_date: 'Start Date: {{startDate}}',
    planned_lot: 'Planned Lot',
    not_planned_lot: 'Not Planned Lot',
    proceed: 'Proceed?',
    confirmed_lots: 'CONFIRMED LOTS',
    serie_note: 'Serie note',
    quality_sheet: 'Quality sheet',
    lot_info: 'Lot(s) info',
    checking_dont_move: 'Checking... Don\'t move the item from the reader!',
    writing_dont_move: 'Writing... Don\'t move the item from the reader!',
    lot_total_pieces: 'Lot total pieces',
    to_be_tested: 'To be tested',
    confirm_lots: 'Confirm lot(s)',
    interrupt_lots: 'Interrupt lot(s)',
    add_discard_causal: 'Add discard causal',
    scan_size_fit_code_to_discard: 'Scan size/fit code to discard',
    items_limit_reached_wsm2: 'Items limit reached for selected size and fit or check advanced quantities on WSM2',
    lot_closed_cannot_encode_items: 'Lot closed correctly, unable to encode other items',
    cannot_encode_items_check_wsm2: 'Unable to encode items, check progress on WSM2',
    warning_message_in_serie: 'Warning message in serie',
    warning_item_just_encoded: 'You are trying to delete an item just encoded. Proceed?',
    item_discarded_with_causal_proceed: 'Item discarded with causal: "{{causal}}". Proceed?',
    no_open_lot: 'No open lot',
    encodable_items: 'Total encodable items',
    close_lot: 'Close lot',
    inspected_1: 'In charge of the worker',
    ready_inspection_2: 'Ready for inspection',
    inspected_2: 'In charge of the inspector',
    suspended: 'Suspended',
    closed: 'Closed',
    interrupted: 'Interrupted',
    reset: 'Reset',
    serie_code: 'Serie code',
    lot_has_discarded_items: 'The lot(s) has discarded items. Manually close the lot(s)',
  },
  error: {
    serie_already_added: 'Serie already added',
    serie_not_found: 'Serie not found',
    error: 'Error',
    forbidden: 'Forbidden',
    not_found: 'Not Found',
    unauthorized: 'Unauthorized',
    too_many_tags_read: 'Too many tags read (expected {{exptected}} read {{read}})',
    too_many_checklist_types: 'Too many checklist types. Make sure to select parcels with same checklist type',
    rfid_discrepancies_found: 'RFID Discrepancies found, clear all and try again or confirm with discrepancies',
    certilogo_not_found: 'Certilogo "{{code}}" not found',
    ean_not_found: 'EAN "{{code}}" not found',
    production_order_not_found: 'Serial number "{{code}}" not found',
    no_inbound_zones: 'No Inbound zones',
    no_operation_config: 'Operation configuration not found',
    not_supported_reading_mode: 'Reading mode not supported',
    undefined_operation_config: 'Operation configuration cannot be undefined',
    certilogo_already_encoded: 'Certilogo "{{code}}" already encoded',
    tag_not_found: 'Tag not found',
    tag_unassociated: 'Tag unassociated',
    tag_type_unexpected: 'Unexpected tag type',
    tag_already_associated: 'Tag already associated',
    tags_not_associated: 'One or more tags are not associated',
    tags_mismatch: 'Tags mismatch',
    workstation_could_not_be_started: 'Workstation could not be started',
    authentication_failed: 'Authentication failed',
    destination_field_required: 'Destination field required',
    unable_to_connect_to_rfid_reader: 'Unable to connect to RFID Reader. Check that the device is turned On',
    items_in_error_found: 'Items in error found',
    item_not_in_inbound: 'Item not in inbound',
    item_not_in_stock: 'Item not in stock',
    item_in_another_parcel: 'Item in another parcel',
    item_in_another_production_order: 'Item in another production order',
    field_is_mandatory: '{{field}} is mandatory',
    field_must_be_number: '{{field}} must be a number',
    serie_status_change: 'Error in the status change of the series in ',
    no_discard_causal_selected: 'Reason for rejection not selected',
    no_product_selected: 'No product selected',
    not_associated_product: 'Product not belonging to the series or wrong size / fit',
    not_collaudated_product: 'Product not collaudated',
    status_not_admitted: 'Status not admitted',
    serie_code_10_characters: 'Serie code different from 10 characters',
    serie_code_14_characters: 'Serie code different from 14 characters',
    serie_wrong_place: "Serie doesn't belong to the user place",
    serie_inspector_check: "Serie must be checked by the inspector",
    product_already_associated: 'Product already associated with the serie',
    product_already_dropped: 'Product already discarded',
    tag_reset_error: "Tag reset error",
    product_drop_error: 'Discard error',
    reader_connection_error: 'Unable to connect to the reader',
    product_irreversible_dropped: 'Product discarded in an irreversible way',
    product_already_associated_by_inspector: "Product already associated by the inspector",
    serie_error: 'Serie error',
    product_not_selected: 'Product not selected',
    serie_limit_associate_error: 'Serie limit associate error',
    writing_tag_error: "Writing tag error",
    product_association_error: "Product association error",
    larger_file_error: 'Maximum upload size is 5MB',
    user_disabled: 'The account is disabled, please contact the administrator',
    cq_type_not_found: 'CQ Type not found',
    counters_error: 'Unable to define serie counters',
    fit_size_not_found: 'Fit/Size not found',
    no_lot_to_be_inspected: 'No lot to be inspected',
    no_lot_selected: 'No lot selected',
    row_not_found: 'Row not found',
    select_causal_before_proceeding: 'Select a causal before proceeding',
    reading_error: 'Reading error',
    cannot_delete_no_item_encoded: 'Cannot delete, no item encoded',
    tested_items_quantity_doesnt_reach_min_quantity: 'The number of parts tested doesn\'t reach the minimum test quantity',
    no_items_tested_cant_advance_lot: 'No tested pieces, can\'t advance the lot',
    no_items_encoded_cant_advance_lot: 'No encoded pieces, can\'t advance the lot',
    testing_sheet_not_set: 'Testing sheet not set',
    encode_one_item_before_close_lot: 'Encode at least one item before closing the lot',
    read_product_not_valid: 'Invalid read product',
    FERRAGAMO_ERROR_BARCODE_NOT_FOUND: 'Serie not found on FAST',
    FERRAGAMO_ERROR_FAST_CONNECTION: 'Connection error at FAST',
    FERRAGAMO_ERROR_SERIES_COMPETENCE_ERROR: 'Serie not belonging to production',
    FERRAGAMO_ERROR_SERIES_STATUS_ERROR: 'Serie in wrong status',
    FERRAGAMO_ERROR_SERIES_STATUS_ERROR_READY_INSPECTION_2: 'Serie in status "to be inspected"',
    FERRAGAMO_ERROR_SERIES_STATUS_ERROR_INSPECTED_2: 'Serie in status "inspected"',
    FERRAGAMO_ERROR_SERIES_STATUS_ERROR_INSPECTED_1: 'Serie in status "working"',
    FERRAGAMO_ERROR_SERIES_STATUS_ERROR_READY_INSPECTION_1: 'Serie in status "to be worked"',
    FERRAGAMO_ERROR_SERIES_STATUS_ERROR_READY_SUSPENDED: 'Serie in status "suspended"',
    FERRAGAMO_ERROR_SERIES_TAG_NEEDED_ERROR: 'Serie with qualityCheckTag other than Y on Fast',
    FERRAGAMO_ERROR_TAG_NOTFOUND_ERROR: 'Tag not found on Fast',
    FERRAGAMO_ERROR_TAG_STATUS_ERROR_ALREADY_TESTED: 'Item already tested',
    FERRAGAMO_ERROR_TAG_STATUS_ERROR_ALREADY_ENCODED: 'Item already encoded',
    FERRAGAMO_ERROR_TAG_STATUS_ERROR_TESTED_BY_INSPECTOR: 'Item tested by inspector',
    FERRAGAMO_ERROR_SERIES_MISMATCH_ERROR: 'Item not of the size/fit read or item not of the series read',
    FERRAGAMO_CQ_ENCODING_ERROR_ORDER_ROW_NOT_FOUND: "Production order row not found",
    FERRAGAMO_CQ_ENCODING_ERROR_ORDER_ROW_PRODUCT_MISMATCH: 'The item you are trying to encode has already been assigned to a different model/fit-size. If necessary, delete',
    FERRAGAMO_CQ_ENCODING_ERROR_ORDER_ROW_ALL_ITEMS_ALREADY_ENCODED: 'All items have already been encoded for the selected model',
    ENCODING_ERROR_PRODUCT_NOT_IN_PRODUCTION_ORDER: "Product not in selected production order",
    FERRAGAMO_ERROR_TAG_STATUS_ERROR_ENCODED: 'Item encoded: the item changes from status OK to status discarded',
    WEBRESOURCE_GENERIC_ERROR: 'Generic error',
    product_already_read: 'Product already read',
    FERRAGAMO_ERROR_SERIES_STATUS_ERROR_CLOSED: 'Serie already tested',
    FERRAGAMO_CQ_RESET_ERROR_ITEM_NOT_IN_OPEN_LOT: 'Item not in an open lot',
    FERRAGAMO_CQ_RESET_ERROR_ITEM_ITEM_IN_CLOSED_LOT: 'Item in a closed lot',
    FERRAGAMO_CQ_TESTING_NO_SELECTED_LOTS_BOUND_TO_ITEM: 'No lots associated with the item',
    FERRAGAMO_ERROR_TAG_STATUS_ERROR_NOT_REVERSIBLE_DISCARDED: 'Item discarded in an irreversible way',
  },
  messages: {
    product_associated_correctly: 'Product associated correctly',
    product_dropped_correctly: 'Product discarded correctly',
    product_cancel_correctly: 'Product canceled correctly',
    product_already_dropped_info: 'Product already discarded with causal',
    product_association_proceed: 'Proceed with the association?',
    serie_confirm_interrupt: 'Confirm interruption',
    serie_interrupt_association_question: 'Are you sure you want to stop testing?',
    serie_closed: 'Serie closed correctly',
    serie_interrupted_correctly: 'Serie interrupted correctly',
    inspected_1: 'Worker',
    ready_inspection_2: 'To be inspected',
    inspected_2: 'Inspector',
    suspended: 'Suspended',
    closed: 'Closed',
    correct: 'Correctly',
    drop_proceed: 'Proceed with the discard?',
    serie_change_confirm: 'Confirm change serie',
    serie_close_product_not_associated: "The product belongs to another series, are you sure you want to close the current series?",
    near_product_to_reader: 'Bring the product close to the reader',
    no_workstations_found: 'No Workstations Found',
    no_workstation_selected: 'No Workstation selected',
    no_place_selected: 'No Place selected',
    no_seat_selected: 'Insert a seat ID',
    are_you_sure_to_reset_daily_items: 'Are you sure you want to reset the daily items?',
    bring_an_item_to_antenna: 'Bring an item close to the antenna',
    press_start_to_read: 'Press Start button to \nstart reading RFID products',
    paste_comma_separeted_tags: 'Paste comma separated tags then press return...',
    connection_workstation_progress: 'Connection to workstation in progress..',
    operation_success: 'Operation successfully confirmed',
    outbound_success: 'Outbound successfully confirmed',
    inbound_success: 'Inbound successfully confirmed',
    transfer_to_zone_success: 'Transfer To Zone successfully confirmed',
    list_empty: 'List is empty',
    looks_like_anything_in_list: "Looks like you don't have anything in your list",
    confirm_change_option: "Confirm change option",
    serie_clear_causals: "Are you sure you want to change option? All causals will be cleared",
    no_cq_type: "Worker doesn't have CQ Type",
    lot_doesnt_contain_testable_items: 'Lot {{lotCode}} doesn\'t contain testable items and will be interrupted',
    lots_dont_contain_testable_items: 'Lots {{lotCodes}} don\'t contain testable items and will be interrupted',
    no_items_tested_lot_interrupted: 'No items tested, lot interrupted',
    lot_closed: 'Lot(s) closed correctly',
    lot_interrupted: 'Lot(s) interrupted correctly',
    select_discard_type: 'Select a discard type',
    vas_serie: 'WARNING: VAS SERIE',
  },
  placeholder: {
    total_pieces: 'Total pieces',
    associated: 'Encoded',
    to_be_tested: 'To be tested',
    drop_causal: 'Discard causal',
    tested: 'Tested',
    dropped: 'Discarded',
    remain: 'Remaining',
    confirm_serie: 'Confirm serie',
    suspend_serie: 'Suspend serie',
    interrupt_association: 'Interrupt testing',
    start_reader: 'Start reader',
    stop_reader: 'Stop reader',
    continue: 'Continue',
    attention: 'Attention',
    search_shipment_parcel: 'Search Shipment or Parcel',
    search_product_parcel: 'Search Product or Parcel',
    reversible: 'Reversible',
    irreversible: 'Irreversible',
    serie_barcode: 'Serie barcode',
  },
  confirm: {
    discrepancy_title: 'Parcel with discrepancies',
    discrepancy_message: 'Products with discrepancies were found. Are you sure you want to confirm the parcel?',
    confirm_delete_association: 'Are you sure you want to delete this item association?',
    serie_found_discrepancy: 'Discrepancies found. Are you sure you want to confirm the series?',
    discarded_pieces_title: 'Discarded pieces confirm',
    discarded_pieces_close_message: 'There are some discarded pieces within the series, do you want to continue with the closure?',
    discrepancy_total_encoded_pieces: 'Attention: series with discrepancies between the total and encoded pieces; pay maximum attention and control even to the unencoded pieces',
  },
  labels: {
    shippable: 'Shippable',
    serie: 'Serie',
    lot: 'Lotto',
    draft: 'Draft',
    ready_to_send: 'Ready to send',
    send_await: 'Pending',
    outbound_error: 'Outbound error',
    sent: 'Sent',
    in_inbound: 'In Inbound',
    receive_await: 'Pending',
    ready_to_receive: 'Readty to receive',
    inbound_error: 'Inbound error',
    received: 'Received',
    in_transit: 'In transit',
    testing: 'Test',
    discard: 'Discard',
    delete: 'Delete',
  },
  caps_lock: {
    lot: 'LOT',
    status: 'STATUS',
    quantity: 'QUANTITY',
    shippable: 'SHIPPABLE',
    details: 'DETAILS'
  }
}
